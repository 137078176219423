export default Object.freeze([
  {
    'attributes': {
      'className': null,
      'id': null,
    },
    'background': {
      'color': null,
      'desktopImage': null,
      'fullWidth': false,
      'hideImageOnMobile': false,
      'imageAltText': null,
      'mobileImage': null,
      'video': null,
    },
    'border': {
      'color': null,
      'radius': null,
      'style': 'none',
      'width': null,
    },
    'columns': [
      {
        'attributes': {
          'className': null,
          'id': null,
        },
        'background': {
          'color': null,
          'desktopImage': null,
          'hideImageOnMobile': false,
          'imageAltText': null,
          'mobileImage': null,
          'video': null,
        },
        'border': {
          'color': null,
          'radius': null,
          'style': 'none',
          'width': null,
        },
        'colspan': {
          'lg': 8,
          'md': 8,
          'sm': 8,
        },
        'columnAnimation': 'NONE',
        'contentBlocks': [
          {
            'horizontalAlignment': 'LEFT',
            'id': '6c8mbdcem',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {},
            'name': 'CallUsMessage',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
        ],
        'contentOrientation': 'VERTICAL',
        'horizontalAlignment': 'JUSTIFY',
        'id': 'l5hou88v7',
        'minHeight': {
          'desktop': null,
          'mobile': null,
          'tablet': null,
        },
        'padding': null,
        'verticalAlignment': 'INHERITED',
      },
      {
        'attributes': {
          'className': null,
          'id': null,
        },
        'background': {
          'color': null,
          'desktopImage': null,
          'hideImageOnMobile': false,
          'imageAltText': null,
          'mobileImage': null,
          'video': null,
        },
        'border': {
          'color': null,
          'radius': null,
          'style': 'none',
          'width': null,
        },
        'colspan': {
          'lg': 4,
          'md': 4,
          'sm': 4,
        },
        'columnAnimation': 'NONE',
        'contentBlocks': [
          {
            'horizontalAlignment': 'LEFT',
            'id': 'gcy46vu9x',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'toggle_Presentation': 0,
              'toggle_FontFamily': null,
              'toggle_FontSize': 'Regular',
              'toggle_AllCaps': false,
              'toggle_FontColor': null,
              'content_ShowFlags': true,
              'content_AllCaps': false,
              'content_ShowExpandIcon': true,
              'content_ItemFontSize': 'Regular',
              'content_ItemFontColor': null,
              'content_ItemHoverFontColor': null,
              'content_BackgroundColor': null,
              'content_ItemHoverBackgroundColor': null,
              'content_BorderColor': null,
            },
            'name': 'DesktopLanguageSelector',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
          {
            'horizontalAlignment': 'LEFT',
            'id': 'iuc9f6dfg',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'toggle_FontFamily': null,
              'toggle_FontSize': 'Regular',
              'toggle_AllCaps': false,
              'toggle_FontColor': null,
              'content_AllCaps': false,
              'content_ShowExpandIcon': true,
              'content_ItemFontSize': 'Regular',
              'content_ItemFontColor': null,
              'content_ItemHoverFontColor': null,
              'content_BackgroundColor': null,
              'content_ItemHoverBackgroundColor': null,
              'content_BorderColor': null,
            },
            'name': 'DesktopCurrencySelector',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
          {
            'horizontalAlignment': 'LEFT',
            'id': 'mfuntmx97',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'toggle_Presentation': 0,
              'toggle_IconColor': null,
              'toggle_FontFamily': null,
              'toggle_ThemeFontSize': 'Regular',
              'toggle_AllCaps': false,
              'toggle_FontColor': null,
              'content_ShowExpandIcon': true,
              'content_ThemeFontSize': 'Regular',
              'content_FontColor': null,
              'content_LinkFontColor': null,
              'content_LinkHoverFontColor': null,
              'content_LinkHoverBackgroundColor': null,
              'content_BackgroundColor': null,
              'content_BorderColor': null,
              'content_SeparatorsColor': null,
            },
            'name': 'DesktopAccountMenu',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
        ],
        'contentOrientation': 'HORIZONTAL',
        'horizontalAlignment': 'RIGHT',
        'id': 'hmek0g6ww',
        'minHeight': {
          'desktop': null,
          'mobile': null,
          'tablet': null,
        },
        'padding': null,
        'verticalAlignment': 'INHERITED',
      },
    ],
    'fullWidth': false,
    'heroEffect': null,
    'id': 'y49rf2u95',
    'minHeight': {
      'desktop': null,
      'mobile': null,
      'tablet': null,
    },
    'rowAnimation': 'NONE',
    'spacing': {
      'hideSpaces': false,
      'margin': null,
      'padding': null,
    },
    'verticalAlignment': 'MIDDLE',
  },
  {
    'attributes': {
      'className': null,
      'id': null,
    },
    'background': {
      'color': null,
      'desktopImage': null,
      'fullWidth': false,
      'hideImageOnMobile': false,
      'imageAltText': null,
      'mobileImage': null,
      'video': null,
    },
    'border': {
      'color': null,
      'radius': null,
      'style': 'none',
      'width': null,
    },
    'columns': [
      {
        'attributes': {
          'className': null,
          'id': null,
        },
        'background': {
          'color': null,
          'desktopImage': null,
          'hideImageOnMobile': false,
          'imageAltText': null,
          'mobileImage': null,
          'video': null,
        },
        'border': {
          'color': null,
          'radius': null,
          'style': 'none',
          'width': null,
        },
        'colspan': {
          'lg': 3,
          'md': 3,
          'sm': 3,
        },
        'columnAnimation': 'NONE',
        'contentBlocks': [
          {
            'horizontalAlignment': 'LEFT',
            'id': 'ewi2x1tql',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {},
            'name': 'Logo',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
        ],
        'contentOrientation': 'HORIZONTAL',
        'horizontalAlignment': 'LEFT',
        'id': 't9vz26j0t',
        'minHeight': {
          'desktop': null,
          'mobile': null,
          'tablet': null,
        },
        'padding': null,
        'verticalAlignment': 'INHERITED',
      },
      {
        'attributes': {
          'className': null,
          'id': null,
        },
        'background': {
          'color': null,
          'desktopImage': null,
          'hideImageOnMobile': false,
          'imageAltText': null,
          'mobileImage': null,
          'video': null,
        },
        'border': {
          'color': null,
          'radius': null,
          'style': 'none',
          'width': null,
        },
        'colspan': {
          'lg': 6,
          'md': 6,
          'sm': 6,
        },
        'columnAnimation': 'NONE',
        'contentBlocks': [
          {
            'horizontalAlignment': 'LEFT',
            'id': '4tjd3gwd3',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {},
            'name': 'SearchBar',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
          {
            'horizontalAlignment': 'LEFT',
            'id': '7zd1gpjg7',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {},
            'name': 'TaxTypeSelector',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
        ],
        'contentOrientation': 'VERTICAL',
        'horizontalAlignment': 'JUSTIFY',
        'id': 'bktszl9uo',
        'minHeight': {
          'desktop': null,
          'mobile': null,
          'tablet': null,
        },
        'padding': null,
        'verticalAlignment': 'INHERITED',
      },
      {
        'attributes': {
          'className': null,
          'id': null,
        },
        'background': {
          'color': null,
          'desktopImage': null,
          'hideImageOnMobile': false,
          'imageAltText': null,
          'mobileImage': null,
          'video': null,
        },
        'border': {
          'color': null,
          'radius': null,
          'style': 'none',
          'width': null,
        },
        'colspan': {
          'lg': 3,
          'md': 3,
          'sm': 3,
        },
        'columnAnimation': 'NONE',
        'contentBlocks': [
          {
            'horizontalAlignment': 'LEFT',
            'id': 'ys5lw1klx',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {},
            'name': 'ProductSuggestions',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
          {
            'horizontalAlignment': 'LEFT',
            'id': '27gozsfda',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'showMiniatureOnBasket': false,
              'basketLink_Layout': 0,
              'basketLink_FontFamily': null,
              'basketLink_ThemeFontSize': 'Regular',
              'basketLink_AllCaps': true,
              'basketLink_IconColor': null,
              'basketLink_FontColor': null,
              'basketLink_EmptyCartFontColor': null,
              'basketLink_BadgeBackgroundColor': null,
              'content_ShowExpandIcon': false,
              'content_ThemeFontSize': 'Regular',
              'content_FontColor': null,
              'content_BackgroundColor': null,
              'content_BorderColor': null,
              'content_SeparatorsColor': null,
              'content_ShowThumbnails': true,
              'heading_FontFamily': null,
              'heading_ThemeFontSize': 'Heading4',
              'heading_AllCaps': true,
              'heading_FontColor': null,
              'footer_BasketLinkStyle': 0,
              'footer_CheckoutLinkStyle': 2,
              'footer_SwapLinksPosition': false,
              'footer_LinkFontColor': null,
              'footer_LinkHoverFontColor': null,
            },
            'name': 'DesktopBasketSummary',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
        ],
        'contentOrientation': 'HORIZONTAL',
        'horizontalAlignment': 'JUSTIFY',
        'id': '97b6u74e6',
        'minHeight': {
          'desktop': null,
          'mobile': null,
          'tablet': null,
        },
        'padding': null,
        'verticalAlignment': 'INHERITED',
      },
    ],
    'fullWidth': false,
    'heroEffect': null,
    'id': 'fc1rueo9h',
    'minHeight': {
      'desktop': null,
      'mobile': null,
      'tablet': null,
    },
    'rowAnimation': 'NONE',
    'spacing': {
      'hideSpaces': false,
      'margin': '20px 0px 19px',
      'padding': null,
    },
    'verticalAlignment': 'MIDDLE',
  },
  {
    'attributes': {
      'className': null,
      'id': null,
    },
    'background': {
      'color': '#F7F7F7',
      'desktopImage': null,
      'fullWidth': false,
      'hideImageOnMobile': false,
      'imageAltText': null,
      'mobileImage': null,
      'video': null,
    },
    'border': {
      'color': null,
      'radius': null,
      'style': 'none',
      'width': null,
    },
    'columns': [
      {
        'attributes': {
          'className': null,
          'id': null,
        },
        'background': {
          'color': null,
          'desktopImage': null,
          'hideImageOnMobile': false,
          'imageAltText': null,
          'mobileImage': null,
          'video': null,
        },
        'border': {
          'color': null,
          'radius': null,
          'style': 'none',
          'width': null,
        },
        'colspan': {
          'lg': 12,
          'md': 12,
          'sm': 12,
        },
        'columnAnimation': 'NONE',
        'contentBlocks': [
          {
            'horizontalAlignment': 'LEFT',
            'id': '3mkpytrog',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'topLevel_FontFamily': null,
              'topLevel_FontSize': null,
              'topLevel_AllCaps': false,
              'topLevel_FontColor': null,
              'topLevel_HoverFontColor': null,
              'topLevel_NonHoveredItemFontColor': null,
              'topLevel_ActiveItemUnderlineColor': null,
              'topLevel_ShowActiveItemUnderline': true,
              'topLevel_ExpandedMenuBackgroundColor': null,
              'topLevel_ExpandedMenuBorderColor': null,
              'topLevel_ItemHeight': null,
              'subLevel_FontFamily': null,
              'subLevel_FontSize': null,
              'subLevel_AllCaps': false,
              'subLevel_FontColor': null,
              'subLevel_HoverFontColor': null,
              'subLevel_HoverBackgroundColor': null,
              'subLevel_ShowArrowInFrontOfSubItem': true,
            },
            'name': 'DesktopCascadingMainMenu',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
        ],
        'contentOrientation': 'VERTICAL',
        'horizontalAlignment': 'JUSTIFY',
        'id': 'oyo8xde4q',
        'minHeight': {
          'desktop': null,
          'mobile': null,
          'tablet': null,
        },
        'padding': null,
        'verticalAlignment': 'INHERITED',
      },
    ],
    'fullWidth': false,
    'heroEffect': null,
    'id': 'z83fjzs8n',
    'minHeight': {
      'desktop': null,
      'mobile': null,
      'tablet': null,
    },
    'rowAnimation': 'NONE',
    'spacing': {
      'hideSpaces': false,
      'margin': null,
      'padding': null,
    },
    'verticalAlignment': 'MIDDLE',
  },
]);
