exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TaxTypeSelector_tax-type-selector-item{display:inline-block;margin-left:6px;margin-right:6px;white-space:nowrap}.TaxTypeSelector_tax-type-selector-item span{margin-top:-1px;margin-right:.5rem}@media (min-width:var(--breakpoints_Medium,960)px),print{.TaxTypeSelector_tax-type-selector-item:first-child{margin-left:0}.TaxTypeSelector_tax-type-selector-item:last-child{margin-right:0}}@media not all and (min-width:var(--breakpoints_Small,600)px){html:not(.print) .TaxTypeSelector_tax-type-selector-item{margin-left:10px;margin-right:10px}html:not(.print) .TaxTypeSelector_tax-type-selector-item:first-child{margin-left:10px;margin-right:10px}html:not(.print) .TaxTypeSelector_tax-type-selector-item:last-child{margin-left:10px;margin-right:10px}}", ""]);

// exports
exports.locals = {
	"tax-type-selector-item": "TaxTypeSelector_tax-type-selector-item",
	"taxTypeSelectorItem": "TaxTypeSelector_tax-type-selector-item"
};