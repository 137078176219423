import { ofType } from 'redux-observable';
import { combineEpics } from 'utils/rxjs';
import {
  map,
  switchMap,
  takeUntil,
  filter,
  pluck,
  tap,
  first,
  mergeMap,
} from 'rxjs/operators';
import {
  currenciesLoaded,
  CURRENCY_CHANGE_URLS_REQUESTED, currencyChangeUrlsLoaded,
  URL_CURRENCY_CHANGED, urlCurrencyChanged,
} from './actions';
import { currenciesQuery, currencyChangeUrlsQuery } from './queries';
import { mapRouteToInput } from './helpers';
import { NAVIGATED, NAVIGATING } from 'behavior/routing';
import { currencyChanged, viewerChanged } from 'behavior/events';
import { APP_INIT } from 'behavior/app';
import { RouteName } from 'routes';

const currenciesEpic = (action$, _state$, { api }) => action$.pipe(
  ofType(APP_INIT),
  switchMap(_ => api.graphApi(currenciesQuery).pipe(
    map(data => currenciesLoaded(data.currencies)),
  )),
);

const currencyChangeUrlsEpic = (action$, state$, { api, scope }) => action$.pipe(
  ofType(CURRENCY_CHANGE_URLS_REQUESTED, scope === 'SERVER' && NAVIGATED),
  switchMap(_ => {
    const state = state$.value;
    if (state.routing.routeData)
      return loadCurrencyChangeUrls(action$, state, api);

    return state$.pipe(
      first(s => s.routing.routeData),
      mergeMap(state => loadCurrencyChangeUrls(action$, state, api)),
    );
  }),
);

const navigateEpic = (action$, state$, { api }) => action$.pipe(
  ofType(NAVIGATED, NAVIGATING),
  pluck('payload', 'routeData', 'params'),
  map(params => params && params.currency),
  filter(currency => (currency && state$.value.settings?.currency?.id) && currency !== state$.value.settings.currency.id),
  tap(currency => currency && api.setCurrency(currency)),
  map(urlCurrencyChanged),
);

const currencyChangedEventEpic = action$ => action$.pipe(
  ofType(URL_CURRENCY_CHANGED),
  filter(a => a.payload),
  map(currencyChanged),
  map(viewerChanged),
);

export default combineEpics(
  currenciesEpic,
  currencyChangeUrlsEpic,
  navigateEpic,
  currencyChangedEventEpic,
);

function loadCurrencyChangeUrls(action$, state, api) {
  const route = mapRouteToInput(state.routing.routeData);
  if (!route.routeName)
    route.routeName = RouteName.NotFound;

  route.key = '';

  route.params.push({ key: 'currentPath', value: state.routing.location.pathname });

  return api.graphApi(currencyChangeUrlsQuery, { route }).pipe(
    map(({ routing }) => currencyChangeUrlsLoaded(routing.currencyChangeUrls)),
    takeUntil(action$.pipe(ofType(NAVIGATED))),
  );
}
