import PropTypes from 'prop-types';
import TaxTypeSelector from './TaxTypeSelector';

const TaxTypeSelectorBlock = ({ id }) => (
  <TaxTypeSelector elementId={id} /> 
);

TaxTypeSelectorBlock.propTypes = {
  id: PropTypes.string,
};

export default TaxTypeSelectorBlock;