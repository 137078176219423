import { ofType } from 'redux-observable';
import { merge, of, from } from 'rxjs';
import { switchMap, map, mergeMap, takeUntil } from 'rxjs/operators';
import { retryWithToast } from 'behavior/errorHandling';
import {
  checkoutInfoUpdated,
  pickupLocationsReceived,
  SHIPPING_METHOD_SELECT,
  PICKUP_LOCATIONS_REQUESTED,
  EXTRA_SHIPPING_DATA_SAVE,
  notifyExtraShippingStepChanged,
} from './actions';
import {
  getSelectShippingMethodMutation,
  getPickupLocationsQuery,
  getSaveExtraShippingStepDataMutation,
  getRefreshExtraShippingStep,
} from './queries';
import { Steps } from './constants';
import { NAVIGATED } from 'behavior/routing';
import { adjustShippingMethodData, adjustPaymentMethodData, navigateOnIncorrect } from './helpers';
import { skipIfPreview } from 'behavior/preview';
import { catchApiValidationErrors } from 'behavior/errorHandling';

export default function createEpic(waitForSubmit) {
  return function (action$, state$, { api, logger }) {
    const isQuote = () => state$.value.page.info?.isQuote || false;
    const isPromotion = () => !!state$.value.page.info?.quote;

    const selectShippingMethod$ = action$.pipe(
      ofType(SHIPPING_METHOD_SELECT),
      skipIfPreview(state$),
      switchMap(({ payload: { id, locationId } }) => waitForSubmit(() => api.graphApi(getSelectShippingMethodMutation(isPromotion()), {
        id,
        locationId,
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        map(({ checkout }) => {
          if (checkout) {
            const { success, info } = checkout.shippingMethod.select;

            if (success) {
              adjustShippingMethodData(info);
              adjustPaymentMethodData(info);

              return checkoutInfoUpdated(info);
            }
          }

          return navigateOnIncorrect(state$.value.page.info);
        }),
        retryWithToast(action$, logger),
      ))),
    );

    const navigated$ = action$.pipe(ofType(NAVIGATED));
    const loadPickupLocations$ = action$.pipe(
      ofType(PICKUP_LOCATIONS_REQUESTED),
      switchMap(({ payload }) => api.graphApi(getPickupLocationsQuery(isPromotion()), payload).pipe(
        map(({ checkout }) => {
          if (!checkout)
            return navigateOnIncorrect(state$.value.page.info);

          return pickupLocationsReceived(payload.methodId, checkout.pickupLocations);
        }),
        retryWithToast(action$, logger),
        takeUntil(navigated$),
      )),
    );

    const saveExtraShippingData$ = action$.pipe(
      ofType(EXTRA_SHIPPING_DATA_SAVE),
      switchMap(({ payload }) => waitForSubmit(() => api.graphApi(getSaveExtraShippingStepDataMutation(isPromotion()), {
        input: { values: payload.values },
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        mergeMap(({ checkout }) => {
          if (!checkout)
            return of(navigateOnIncorrect(state$.value.page.info));

          const extraStepResult = checkout.shippingMethod.extraCheckoutStep;
          if (!extraStepResult) {
            return of(checkoutInfoUpdated({
              extraShippingStep: null,
              steps: state$.value.page.info.steps.filter(s => s.id !== Steps.ExtraShipping),
            }));
          }

          const checkoutInfo = extraStepResult.saveData.info;
          const extraShippingStep = checkoutInfo?.shippingMethod?.extraShippingCheckoutStep;

          const result = [];
          if (extraShippingStep) {
            extraShippingStep.errors = null;
            result.push(notifyExtraShippingStepChanged(extraShippingStep));
          }

          if (checkoutInfo?.steps)
            result.push(checkoutInfoUpdated({ steps: checkoutInfo.steps, ...checkoutInfo }));
          else if (!extraShippingStep)
            return of(navigateOnIncorrect(state$.value.page.info));

          return from(result);
        }),
        catchApiValidationErrors(errors =>
          api.graphApi(getRefreshExtraShippingStep(isPromotion())).pipe(
            map(({ checkout }) =>
              notifyExtraShippingStepChanged({
                errors,
                ...checkout?.shippingMethod?.extraShippingCheckoutStep,
              }),
            ),
          ),
        ),
        retryWithToast(action$, logger),
      ))),
    );

    return merge(selectShippingMethod$, loadPickupLocations$, saveExtraShippingData$);
  };
}
