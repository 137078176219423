import styles from './CurrencySelector.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';

const Header = ({ selectedCurrency, className = '' }) => {
  const { name } = selectedCurrency;

  return (
    <div className={`${styles.header} ${className}`}>
      <span>{name}</span>
    </div>
  );
};

Header.propTypes = {
  selectedCurrency: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

export default memo(Header);
