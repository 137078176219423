export const CURRENCIES_LOADED = 'CURRENCIES/LOADED';
export const currenciesLoaded = currencies => ({ type: CURRENCIES_LOADED, payload: currencies });

export const CURRENCY_CHANGE_URLS_REQUESTED = 'CURRENCIES/CURRENCY_CHANGE_URLS/REQUESTED';
export const loadCurrencyChangeUrls = () => ({ type: CURRENCY_CHANGE_URLS_REQUESTED });

export const CURRENCY_CHANGE_URLS_LOADED = 'CURRENCIES/CURRENCY_CHANGE_URLS/LOADED';
export const currencyChangeUrlsLoaded = CurrencyChangeUrls => ({ type: CURRENCY_CHANGE_URLS_LOADED, payload: CurrencyChangeUrls });

export const URL_CURRENCY_CHANGED = 'URL/CURRENCY/CHANGED';
export const urlCurrencyChanged = newCurrencyId => ({ type: URL_CURRENCY_CHANGED, payload: newCurrencyId });