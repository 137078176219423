import styles from './CurrencySelector.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';

const MobileHeader = ({ selectedCurrency }) => {
  const { name } = selectedCurrency;

  return (
    <div className={styles.header}>
      <span>{name}</span>
    </div>
  );
};

MobileHeader.propTypes = {
  selectedCurrency: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(MobileHeader);
