import { TAX_TYPE_CHANGED } from './actions';
import { createReducer } from 'utils/redux';

const initialState = {
  selectedPriceInclTax: null,
};

export default createReducer(initialState, {
  [TAX_TYPE_CHANGED]: onTaxTypeChanged,
});

function onTaxTypeChanged(state, action){
  return {
    ...state,
    selectedPriceInclTax: action.payload,
  };
};