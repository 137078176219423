import { NAVIGATION_REQUESTED, viewerChanged } from 'behavior/events';
import { TAX_TYPE_CHANGED } from './actions';
import { merge, NEVER, of, Subject } from 'rxjs';
import { ofType } from 'redux-observable';
import { pluck, tap, delay, takeUntil, mergeMap, switchMap, catchError } from 'rxjs/operators';
import { reloadLocation } from 'behavior/routing';
import { UserType } from 'behavior/user';
import { createViewerQuery } from 'behavior/user/queries';
import { createUserData, handleToken } from 'behavior/user/helpers';
import { authenticated } from 'behavior/user/actions';

const expirations$ = new Subject();

const taxTypeEpic = (action$, state$, dependencies) => {
  const { api, logger } = dependencies;

  const taxTypeChangedAction = action$.pipe(
    ofType(TAX_TYPE_CHANGED),
    pluck('payload'),
    tap(input => api.setSelectedPriceInclTax(input)),
    switchMap(() => {
      const requestOptions = {
        useCookies: true,
      };

      return api.graphApi(createViewerQuery(true, true), { keys: getAbilitiesKeys(state$) }, requestOptions).pipe(
        handleToken(api, expirations$),
        mergeMap(({ viewer }) => {
          const userData = createUserData(viewer, isAuthenticated(viewer));
          
          return merge(
            of(reloadLocation()).pipe(
              delay(10),
              takeUntil(action$.pipe(ofType(NAVIGATION_REQUESTED))),
            ),
            of(authenticated(userData), viewerChanged()),
          );
        }),
        catchError(e => {
          logger.error(e);
          return NEVER;
        }),
      );
    }),
  );

  return taxTypeChangedAction;
};

export default taxTypeEpic;

function getAbilitiesKeys(state$) {
  const abilitiesFromState = Object.keys(state$.value.user.abilities);
  if (abilitiesFromState.length)
    return abilitiesFromState;

  return mostUsedAbilities;
}

function isAuthenticated(viewer) {
  return viewer.type === UserType.Registered || viewer.type === UserType.Admin;
}