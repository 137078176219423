import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadCurrencyChangeUrls } from 'behavior/currencies';
import { useCurrencyInfo } from 'utils/hooks';

export default () => {
  const dispatch = useDispatch();
  const currentCurrency = useCurrencyInfo();
  const { currencies, currencyChangeUrls } = useSelector(state => state.currencies);

  const selectedCurrency = currentCurrency ? currencies.find(l => l.id === currentCurrency.id) : null ?? (currencies.length ? currencies[0] : null);

  const sortedCurrencies = useMemo(() => selectedCurrency ? [
    selectedCurrency,
    ...currencies.filter(currency => currency !== selectedCurrency),
  ] : null, [currencies, selectedCurrency]);

  return {
    currenciesReady: !!selectedCurrency && !!sortedCurrencies,
    selectedCurrency,
    sortedCurrencies,
    currencyChangeUrls,
    loadCurrencyChangeUrls: useCallback(() => {
      if (currencyChangeUrls == null || !currencyChangeUrls.length)
        dispatch(loadCurrencyChangeUrls());
    }, [currencyChangeUrls]),
  };
};
