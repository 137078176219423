import { CURRENCIES_LOADED, CURRENCY_CHANGE_URLS_LOADED, URL_CURRENCY_CHANGED } from './actions';
import { NAVIGATED } from 'behavior/routing';
import { LOCATION_CHANGED } from 'behavior/events';
import { createReducer } from 'utils/redux';

const initialState = {
  currencies: [],
  currentCurrency: {
    id: null,
    name: null,
  },
  currencyChangeUrls: [],
  currencyChangeUrlsExpired: false,
};

export default createReducer(initialState, {
  [CURRENCIES_LOADED]: onCurrenciesLoaded,
  [CURRENCY_CHANGE_URLS_LOADED]: onCurrencyChangeUrlsLoaded,
  [LOCATION_CHANGED]: onLocationChanged,
  [URL_CURRENCY_CHANGED]: onUrlCurrencyChanged,
  [NAVIGATED]: onNavigated,
});

function onCurrenciesLoaded(state, action) {
  const currencies = action.payload;

  return {
    ...state,
    currencies,
    currentCurrency: createCurrentCurrency(state.currentCurrency.id, currencies),
  };
}

function onCurrencyChangeUrlsLoaded(state, action) {
  return { ...state, currencyChangeUrls: action.payload };
}

function onLocationChanged(state, _action) {
  return { ...state, currencyChangeUrlsExpired: true };
}

function onNavigated(state, _action) {
  if (state.currencyChangeUrlsExpired)
    return { ...state, currencyChangeUrlsExpired: false, currencyChangeUrls: [] };

  return state;
}

function onUrlCurrencyChanged(state, action) {
  const id = action.payload;

  return {
    ...state,
    currentCurrency: createCurrentCurrency(id, state.currencies),
  };
}

/**
 * Finds a currency in an array by the specified currency ID.
 * @param {Number} id - ID of the currency.
 * @param {Array} currencies - currencies array.
 * @returns {Object} - found currency, or null in case currency wasn't found.
 */
function findCurrencyById(id, currencies) {
  if (!currencies)
    return;
  return currencies.find(l => l.id === id);
}

function createCurrentCurrency(id, currencies) {
  const currency = findCurrencyById(id, currencies);

  return {
    id,
    name: currency ? currency.name : null,
  };
}