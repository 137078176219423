export default Object.freeze([
  {
    'attributes': {
      'className': null,
      'id': null,
    },
    'background': {
      'color': null,
      'desktopImage': null,
      'fullWidth': false,
      'hideImageOnMobile': false,
      'imageAltText': null,
      'mobileImage': null,
      'video': null,
    },
    'border': {
      'color': null,
      'radius': null,
      'style': 'none',
      'width': null,
    },
    'columns': [
      {
        'attributes': {
          'className': null,
          'id': null,
        },
        'background': {
          'color': null,
          'desktopImage': null,
          'hideImageOnMobile': false,
          'imageAltText': null,
          'mobileImage': null,
          'video': null,
        },
        'border': {
          'color': null,
          'radius': null,
          'style': 'none',
          'width': null,
        },
        'colspan': {
          'lg': 12,
          'md': 12,
          'sm': 12,
        },
        'columnAnimation': 'NONE',
        'contentBlocks': [
          {
            'horizontalAlignment': 'LEFT',
            'id': 'uvlc2nxrm',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'topLevel_AllCaps': true,
              'topLevel_ShowActiveItemUnderline': true,
              'subLevel_AllCaps': false,
              'subLevel_ShowArrowInFrontOfSubItem': false,
            },
            'name': 'MobileMainMenu',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
          {
            'horizontalAlignment': 'CENTER',
            'id': '5mbvfbkv8',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {},
            'name': 'Logo',
            'packId': null,
            'spacing': {
              'margin': '8px 0px 8px 0px',
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': true,
          },
          {
            'horizontalAlignment': 'CENTER',
            'id': '1bpunu17g',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'content_ShowExpandIcon': false,
              'content_ThemeFontSize': 'Regular',
            },
            'name': 'MobileAccountMenu',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
          {
            'horizontalAlignment': 'LEFT',
            'id': 'dd19aqk6x',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'showMiniatureOnBasket': false,
              'basketLink_ShowNumberOfProductsAsBadge': false,
              'basketLink_ThemeFontSize': 'Regular',
            },
            'name': 'MobileBasketSummary',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
        ],
        'contentOrientation': 'HORIZONTAL',
        'horizontalAlignment': 'JUSTIFY',
        'id': 'xktl3cvkt',
        'minHeight': {
          'desktop': null,
          'mobile': null,
          'tablet': null,
        },
        'padding': null,
        'verticalAlignment': 'INHERITED',
      },
    ],
    'fullWidth': true,
    'heroEffect': null,
    'id': 'wtxp1m9wj',
    'minHeight': {
      'desktop': null,
      'mobile': null,
      'tablet': null,
    },
    'rowAnimation': 'NONE',
    'spacing': {
      'hideSpaces': true,
      'margin': null,
      'padding': null,
    },
    'verticalAlignment': 'MIDDLE',
  }, {
    'attributes': {
      'className': null,
      'id': null,
    },
    'background': {
      'color': null,
      'desktopImage': null,
      'fullWidth': false,
      'hideImageOnMobile': false,
      'imageAltText': null,
      'mobileImage': null,
      'video': null,
    },
    'border': {
      'color': null,
      'radius': null,
      'style': 'none',
      'width': null,
    },
    'columns': [
      {
        'attributes': {
          'className': null,
          'id': null,
        },
        'background': {
          'color': null,
          'desktopImage': null,
          'hideImageOnMobile': false,
          'imageAltText': null,
          'mobileImage': null,
          'video': null,
        },
        'border': {
          'color': '#E6E6E6',
          'radius': null,
          'style': 'solid',
          'width': '1px 0px 1px 0px',
        },
        'colspan': {
          'lg': 12,
          'md': 12,
          'sm': 12,
        },
        'columnAnimation': 'NONE',
        'contentBlocks': [
          {
            'horizontalAlignment': 'LEFT',
            'id': 'vqfu4wer4',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'content_ShowFlags': true,
              'content_AllCaps': false,
              'content_ShowExpandIcon': false,
              'content_ItemFontSize': 'Regular',
            },
            'name': 'MobileLanguageSelector',
            'packId': null,
            'spacing': {
              'margin': '0 8px 0 0',
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
          {
            'horizontalAlignment': 'LEFT',
            'id': '0r0uld48a',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {
              'content_AllCaps': false,
              'content_ShowExpandIcon': false,
              'content_ItemFontSize': 'Regular',
            },
            'name': 'MobileCurrencySelector',
            'packId': null,
            'spacing': {
              'margin': '0 8px 0 0',
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
          {
            'horizontalAlignment': 'LEFT',
            'id': 'swxso5p8e',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {},
            'name': 'SearchBar',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': true,
          },
          {
            'horizontalAlignment': 'LEFT',
            'id': '5h2rc7x3a',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {},
            'name': 'TaxTypeSelector',
            'packId': null,
            'spacing': {
              'margin': null,
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
          {
            'horizontalAlignment': 'LEFT',
            'id': '1epqvnhhg',
            'minHeight': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'minWidth': {
              'desktop': null,
              'mobile': null,
              'tablet': null,
            },
            'model': {},
            'name': 'ProductSuggestions',
            'packId': null,
            'spacing': {
              'margin': '0 0 0 8px',
              'padding': null,
            },
            'stretchHeight': false,
            'stretchWidth': false,
          },
        ],
        'contentOrientation': 'HORIZONTAL',
        'horizontalAlignment': 'JUSTIFY',
        'id': 'eg0bl5l02',
        'minHeight': {
          'desktop': null,
          'mobile': null,
          'tablet': null,
        },
        'padding': '8px',
        'verticalAlignment': 'INHERITED',
      },
    ],
    'fullWidth': true,
    'heroEffect': null,
    'id': 'atqugdj8d',
    'minHeight': {
      'desktop': null,
      'mobile': null,
      'tablet': null,
    },
    'rowAnimation': 'NONE',
    'spacing': {
      'hideSpaces': true,
      'margin': null,
      'padding': null,
    },
    'verticalAlignment': 'MIDDLE',
  },
]);
