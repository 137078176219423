import { memo } from 'react';
import PropTypes from 'prop-types';
import { getFormatPrice } from 'utils/format';

const PriceNode = ({ price, currencyInfo, className }) => {
  const formatAsPrice = currencyInfo && getFormatPrice(currencyInfo);
  return <span className={className}>{formatAsPrice(price)}</span>;
};

PriceNode.propTypes = {
  price: PropTypes.number,
  currencyInfo: PropTypes.object,
  className: PropTypes.string,
};

export default memo(PriceNode);