import { useCallback, useState } from "react";
import PropTypes from 'prop-types';
import { Radio } from "components/primitives/form";
import { useSanaTexts } from "components/sanaText";
import { useSelector, connect } from 'react-redux';
import { taxTypeChanged } from "behavior/taxType/actions";
import styles from './TaxTypeSelector.module.scss';

const TaxTypeSelector = ({ elementId, taxTypeChanged }) => {
  const { pricesInclTax } = useSelector(state => state.user);
  const [ selectedPricesInclTax, setSelectedPricesInclTax ] = useState(pricesInclTax);
  const [ inclTaxLabel, exclTaxLabel ] = useSanaTexts(['TaxTypeSelector_InclTax', 'TaxTypeSelector_ExclTax']).texts;

  const items = [
    { name: inclTaxLabel, value: true, },
    { name: exclTaxLabel, value: false, },
  ];

  const onChange = useCallback((value) => {
    taxTypeChanged(value ? 'true' : 'false');
    setSelectedPricesInclTax(value);
  });

  return items.map(({ name, value }) => {
    const radioButtonId = `${elementId}_${value}`;

    return (
      <Radio
        key={radioButtonId}
        id={radioButtonId}
        value={value}
        onChange={onChange && (() => onChange(value))}
        checked={selectedPricesInclTax === value}
        className={styles.taxTypeSelectorItem}
      >
        {name}
      </Radio>
    );
  });
};

TaxTypeSelector.propTypes = {
  elementId: PropTypes.string.isRequired,
  taxTypeChanged: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  taxTypeChanged,
};

export default connect(null, mapDispatchToProps)(TaxTypeSelector);