import styles from './CurrencySelector.module.scss';
import { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Placeholder } from 'components/primitives/placeholders';
import { ModalDrawer } from 'components/primitives/modalDrawer';
import { useOnChange } from 'utils/hooks';
import { Helmet } from 'react-helmet';
import { getThemeFontSizeClassName } from 'components/theme';
import useCurrencySelectorBehavior from './useCurrencySelectorBehavior';
import MobileHeader from './MobileHeader';
import Body from './Body';
import { useHasAbilities } from '../user';
import { AbilityTo } from 'behavior/user/constants';

const MobileCurrencySelectorBlock = ({ model, isDesignerMode, id }) => {
  const {
    currenciesReady,
    selectedCurrency,
    sortedCurrencies,
    currencyChangeUrls,
    loadCurrencyChangeUrls,
  } = useCurrencySelectorBehavior();
  const [opened, setOpened] = useState(false);
  const isLoading = useSelector(state => state.isLoading);

  const handleOpen = useCallback(e => {
    if (e.type === 'keydown' && e.key !== 'Enter' && e.which !== 13)
      return;

    loadCurrencyChangeUrls();
    setOpened(!opened);
  }, [opened, loadCurrencyChangeUrls]);
  const handleClose = useCallback(() => setOpened(false), []);
  const [canChangeCurrency] = useHasAbilities(AbilityTo.ChangeCurrency);

  useOnChange(() => isLoading && handleClose(), [isLoading], false);

  if (!canChangeCurrency || !currenciesReady || (sortedCurrencies.length <= 1 && !isDesignerMode))
    return null;

  const currencySelectorId = `MobileCurrencySelector_${id}`;

  return (
    <>
      <Helmet>
        <style>{`
          #${currencySelectorId} {
            background-color: ${model.content_BackgroundColor || '#fff'};
            color: ${model.content_ItemFontColor || 'inherit'};
            ${model.content_AllCaps ? 'text-transform: uppercase;' : ''}
          }
          #${currencySelectorId} .${styles.mobileItem} {
            color: ${model.content_ItemFontColor || 'inherit'};
          }
        `}</style>
      </Helmet>
      <div
        tabIndex="0"
        role="button"
        disabled={isLoading || null}
        className={styles.mobileOpener}
        onKeyDown={handleOpen}
        onClick={handleOpen}
        aria-controls={isDesignerMode ? null : currencySelectorId}
      >
        <MobileHeader selectedCurrency={selectedCurrency} />
        {model.content_ShowExpandIcon && (
          <span className={styles.caret}>
            <FontAwesomeIcon icon={faCaretDown} />
          </span>
        )}
      </div>
      {!isDesignerMode && (
        <ModalDrawer
          expanded={opened}
          onClose={handleClose}
          id={currencySelectorId}
          className={`${styles.drawer} ${getThemeFontSizeClassName(model.content_ItemFontSize)}`}
        >
          {currencyChangeUrls && currencyChangeUrls.length
            ? (
              <Body
                currencies={sortedCurrencies}
                currencyChangeUrls={currencyChangeUrls}
                className={styles.mobileItem}
              />
            )
            : (
              <div className={`${styles.placeholder} ${styles.mobile}`}>
                <Placeholder lineCount={sortedCurrencies.length} className={styles.placeholderItem} />
              </div>
            )
          }
        </ModalDrawer>
      )}
    </>
  );
};

MobileCurrencySelectorBlock.propTypes = {
  model: PropTypes.shape({
    content_AllCaps: PropTypes.bool.isRequired,
    content_ShowExpandIcon: PropTypes.bool.isRequired,
    content_BackgroundColor: PropTypes.string,
    content_ItemFontSize: PropTypes.string.isRequired,
    content_ItemFontColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

export default memo(MobileCurrencySelectorBlock);
