import DesktopCurrencySelectorBase from './DesktopCurrencySelectorBase';
import { ThemeFontSize } from 'behavior/theme';

const defaultOptions = {
  toggle_FontSize: ThemeFontSize.Regular,
  toggle_AllCaps: false,
  content_AllCaps: false,
  content_ShowExpandIcon: true,
  content_ItemFontSize: ThemeFontSize.Regular,
};

const DesktopCurrencySelector = () => (
  <DesktopCurrencySelectorBase options={defaultOptions} elementId="desktopCurrencySelector" />
);

export default DesktopCurrencySelector;
