import styles from './CurrencySelector.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import useCurrencySelectorBehavior from './useCurrencySelectorBehavior';
import { Dropdown } from 'components/primitives/dropdown';
import Header from './Header';
import Body from './Body';
import { Placeholder } from 'components/primitives/placeholders';
import { getThemeFontSizeClassName } from 'components/theme';
import { Helmet } from 'react-helmet';
import { mobileSafariMediaQuery } from 'utils/detections';
import { mousePointerClassName } from 'components/detection';
import { useHasAbilities } from '../user';
import { AbilityTo } from 'behavior/user/constants';

const DesktopCurrencySelectorBase = ({ options, isDesignerMode, elementId }) => {
  const {
    currenciesReady,
    selectedCurrency,
    sortedCurrencies,
    currencyChangeUrls,
    loadCurrencyChangeUrls,
  } = useCurrencySelectorBehavior();

  const [canChangeCurrency] = useHasAbilities(AbilityTo.ChangeCurrency);

  if (!canChangeCurrency || !currenciesReady || (sortedCurrencies.length <= 1 && !isDesignerMode))
    return null;

  const currencySelectorId = `DesktopCurrencySelector_${elementId}`;
  const toggleFontSizeClassName = getThemeFontSizeClassName(options.toggle_FontSize);
  const dropdownItemFontSizeClassName = getThemeFontSizeClassName(options.content_ItemFontSize);

  return (
    <>
      <Helmet>
        <style>{`
          ${options.toggle_FontFamily || options.toggle_FontColor || options.toggle_AllCaps ? `
            #${currencySelectorId} .dropdown-header {
              ${options.toggle_FontFamily ? `font-family: ${options.toggle_FontFamily}, sans-serif;` : ''}
              ${options.toggle_FontColor ? `color: ${options.toggle_FontColor};` : ''}
              ${options.toggle_AllCaps ? 'text-transform: uppercase;' : ''}
            }
          ` : ''}
          #${currencySelectorId} .${styles.item} {
            color: ${options.content_ItemFontColor || '#333'};
          }
          #${currencySelectorId}.dropdown-opened,
          #${currencySelectorId}.dropdown .dropdown-body {
            background-color: ${options.content_BackgroundColor || '#fff'} !important;
            border-color: ${options.content_BorderColor || '#e6e6e6'} !important;
          }
          ${options.content_AllCaps ? `
            #${currencySelectorId}.dropdown .dropdown-body {
              ${options.content_AllCaps ? 'text-transform: uppercase;' : ''}
            }
          ` : ''}
          #${currencySelectorId}.dropdown-opened .dropdown-header {
            color: ${options.content_ItemFontColor || '#333'};
          }
          html.${mousePointerClassName} #${currencySelectorId} .${styles.item}:hover {
            background-color: ${options.content_ItemHoverBackgroundColor || '#f7f7f7'};
            color: ${options.content_ItemHoverFontColor || '#333'};
          }
          ${mobileSafariMediaQuery} {
            #${currencySelectorId} .${styles.item}:hover {
              -webkit-tap-highlight-color: ${options.content_ItemHoverFontColor || '#333'};
            }
          }
        `}</style>
      </Helmet>
      <Dropdown
        id={currencySelectorId}
        className={styles.dropdown}
        onDropDownShow={loadCurrencyChangeUrls}
        hideCaretDown={!options.content_ShowExpandIcon}
        header={<Header selectedCurrency={selectedCurrency} className={toggleFontSizeClassName} />}
      >
        {!isDesignerMode && (
          currencyChangeUrls && currencyChangeUrls.length
            ? (
              <Body
                currencies={sortedCurrencies}
                currencyChangeUrls={currencyChangeUrls}
                className={dropdownItemFontSizeClassName}
              />
            )
            : (
              <div className={styles.placeholder}>
                <Placeholder lineCount={sortedCurrencies.length} className={styles.placeholderItem} />
              </div>
            )
        )}
      </Dropdown>
    </>
  );
};

DesktopCurrencySelectorBase.propTypes = {
  options: PropTypes.shape({
    toggle_FontFamily: PropTypes.string,
    toggle_FontSize: PropTypes.string.isRequired,
    toggle_AllCaps: PropTypes.bool.isRequired,
    toggle_FontColor: PropTypes.string,
    content_AllCaps: PropTypes.bool.isRequired,
    content_ShowExpandIcon: PropTypes.bool.isRequired,
    content_BackgroundColor: PropTypes.string,
    content_ItemHoverBackgroundColor: PropTypes.string,
    content_BorderColor: PropTypes.string,
    content_ItemFontSize: PropTypes.string.isRequired,
    content_ItemFontColor: PropTypes.string,
    content_ItemHoverFontColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
  elementId: PropTypes.string.isRequired,
};

export default memo(DesktopCurrencySelectorBase);
