export const enum ProductSpecificationFilter {
  ForList = 'FOR_LIST',
  ForDetails = 'FOR_DETAILS',
  ForComparison = 'FOR_COMPARISON',
}

export const enum OptionMembers {
  Normal = 'Normal',
  Blocked = 'Blocked',
  Registered = 'Registered',
  SpareParts = 'SpareParts',
}
